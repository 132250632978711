import React from "react";
import { Link } from "react-router-dom";
import I18n from "./I18n";
import styled from 'styled-components'

import i from "../../static/icons/mail.png";
import logo from "../../static/icons/brightcore-logo.svg"
import youtube from "../../static/icons/youtube-link-btn.svg"
import linkedIn from "../../static/icons/linkedin-link-btn.png"

const Wrapper = styled.section`
  color: ${props => props.theme.colors['white']};
  overflow: auto;
  padding: 50px 0 40px;
  background-color: ${props => props.theme.colors['blazeOrange']}
`
const Inner = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Logo = styled.div`
  width: 100%;
  text-align: left;
`

const Block = styled.div`
  @media screen and (max-width: 767px) {
    width: 100%;
    ${props => props.nodisplay ? 'display: none' : null}
  }

  width: ${props => props.val}
`

const Title = styled.h4`
  margin: 20px 0;
  font-size: 3rem;
  ${props => `text-align: ${props.align}`} 
`

const AddressContent = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  text-align: left;
`

const Form = styled.form`
  @media screen and (max-width: 767px) {
    display: none;
  }
  
  display: block;
  position: relative;
`

const InputForm = styled.input`
  appearance: none;
  max-width: 350px;
  width: 100%;
  border: 1px solid ${props => props.theme.colors['white']};
  background-color: transparent;
  padding: 20px 0;
  font-size: 1.6rem;
  color: ${props => props.theme.colors['white']};

  &::placeholder {
    padding-left: 10px;
    color: ${props => props.theme.colors['white']};
    font-size: 1.6rem;
  }
`

const InputButton = styled.input`
  position: absolute;
  appearance: none;
  top: 0;
  right: -1px;
  height: 60px;
  width: 65px;
  border: 1px solid ${props => props.theme.colors['white']};
  color: ${props => props.theme.colors['blazeOrange']};
  font-size: 1.6rem;
`

const Bottom = styled.div`
  @media screen and (max-width: 767px) {
    flex-direction: column; 
  }

  flex-direction: row; 
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`

const Links = styled.ul`
  @media screen and (max-width: 767px) {
    justify-content:${props => props.justify} ;
    order: ${props => props.order};
  }

  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
`

const Item = styled.li`
  @media screen and (max-width: 767px) {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-right: ${props => props.setMargin ? props.setMargin : 0};
  }

  font-size: 1.4rem;
  font-weight: bold;
  margin-right: 20px;
`
const Image = styled.img`
  height: 17px;
`

const placeholder = {
  en: 'Email',
  ja: 'Eメール'
}


export default function Footer(props) {
  return (
    <Wrapper>
      <Inner>
        <Logo>
          <img
            src={logo}
            alt="logo"
          />
        </Logo>
        <Block val={'66%'}>
          <Title align='left'><I18n t="address.title" /></Title>
          <AddressContent>
            <I18n t="address.street" />
          </AddressContent>
        </Block>
        <Block val={'33%'} nodisplay>
          <Title align="right"><I18n t="touch" /></Title>
          <Form>
            <InputForm
              type="text"
              name="email"
              placeholder={placeholder[props.lang]}
            />
            <InputButton
              type="submit"
              value=">"
            />
          </Form>
        </Block>
        <Bottom>
          <Links order='2' justify="space-between">
            <Item>
              <I18n t="company" />
            </Item>
            <Item>
              <Link to="/"><I18n t="terms" /></Link>
            </Item>
            <Item>
              <Link to="/"><I18n t="privacy" /></Link>
            </Item>
          </Links>
          <Links>
            <Item setMargin={'20px'}>
              <a href="https://www.linkedin.com/company/brightcore-inc" target="_blank" rel="noopener noreferrer">
                <Image
                  src={linkedIn}
                  alt="linkedIn"
                />
              </a>
            </Item>
            <Item setMargin={'20px'}>
              <a href="https://www.youtube.com/channel/UC__uold92FnR3b43awjYpUA" target='_blank' rel="noopener noreferrer">
                <img
                  src={youtube}
                  alt="youtube"
                />
              </a>
            </Item>
            {/* <Item setMargin={'20px'}>
              <Link to={props.to}>
                <Image src={i} alt="mail" />
              </Link>
            </Item> */}
          </Links>
        </Bottom>
      </Inner>
    </Wrapper>
  );
}
