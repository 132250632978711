import React from 'react'
import styled from 'styled-components'

const ButtonContent = styled.span`
  @media screen and (max-width: 767px) {
    font-size: 1.8rem;
    padding: 17px 30px;
  }
  
  background-color: ${props => props.theme.colors.blazeOrange};
  color: ${props => props.theme.colors.white};
  padding: 17px 55px;
  border-radius: 100px;
  font-size: 2.6rem;
  transition: all 0.3s ease;
  border: 2px solid ${props => props.theme.colors.blazeOrange};
  ${props => props.bold ? 'font-weight: 700' : null};


  :hover {
    color: ${props => props.theme.colors.blazeOrange};
    background-color: ${props => props.theme.colors.white};

  }
`

export default function Button(props) {
  return (
    <ButtonContent bold={props.bold}>
      {props.children}
    </ButtonContent>
  )
}