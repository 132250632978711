import React from "react";
import styles from "./style.module.scss";
import Description from "../../components/Desc";
import Display from "../../components/Display";
import Duo from "../../components/DuoImage";
import I18n from "./I18n";
import HeroVideo from "../../components/HeroVideo";
import Fade from "react-reveal/Fade";
import Button from '../../components/Button'
import styled, { ThemeProvider } from 'styled-components'
import Tile from '../../components/Tile'
import theme from "../../theme"
import Footer from "../../components/Footer"
// import App from 'firebase/index'

import bg1 from "../../static/images/landing/consistent.jpg";
import bg2 from "../../static/images/landing/durable.jpg";
import bg3 from "../../static/images/landing/uidriven.jpg";

import pic2 from "../../static/images/landing/dripcoffee.jpg";
import pic1 from "../../static/images/yaam/yaam_2.jpg";

import video from "../../static/video/web/yaam.mp4";
import mobileVideo from "../../static/video/mobile/yaam.mp4";


const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
`

const VideoWrapper = styled.div`
  max-width: 800px;
  margin: 50px auto 0;

  iframe{
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.3);
  }
`

const Content = styled.div`
  @media screen and (max-width: 767px) {
    padding: 50px 20px;
  }
  padding: 100px 20px;
`

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 767px) {
    bottom: -10px;
}
`

const TextWrapper = styled.div`
white-space: pre-line;
margin: 24px 0;
`

const TextSection = styled.div`
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  max-width: 1125px;
  margin: 0 auto;

  text-align: left;
  font-size: 2.4rem;
  color: ${props => props.theme.colors['text']};
  line-height: 1.5;
  margin-top: 50px;

`

export default function Products(props) {

  function fireEvent() {
    // App.analytics().logEvent('download brochure');
  }

  const path = props.match.params.locale ? `/files/${props.match.params.locale}/yaam-cafe-and-bar_${props.match.params.locale}.pdf` : `/files/en/yaam-cafe-and-bar_en.pdf`

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <HeroVideo
          video={video}
          mobileVideo={mobileVideo}
          ttl={<I18n t="hero.title" />}
        >
          <I18n t="hero.content" />
        </HeroVideo>

        <div style={{ position: 'relative' }}>
          <Display title={<I18n t="display.title" />} color="orange">
            <Tile title={<I18n t="display.tile_1" />} bg={bg1} txt={<I18n t="display.hover_1" />} />
            <Tile title={<I18n t="display.tile_2" />} bg={bg2} txt={<I18n t="display.hover_2" />} />
            <Tile title={<I18n t="display.tile_3" />} bg={bg3} txt={<I18n t="display.hover_3" />} />
          </Display>

          <ButtonWrapper onClick={fireEvent}>
            <a href={path} download style={{ textDecoration: 'none' }}>
              <Button>
                <I18n t="btn" />
              </Button>
            </a>
          </ButtonWrapper>
        </div>

        <Content>
          <Description title={<I18n t="description_1.title" />} hidden>
            <div>
              <I18n t="description_1.content1" />
            </div>
          </Description>

          <VideoWrapper>
            <Fade>
              <iframe
                title="bc-story"
                width="100%"
                height="450"
                src="https://www.youtube.com/embed/9EoIURjJVU4"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Fade>
          </VideoWrapper>


          <TextSection>
            <TextWrapper>
              <I18n t="description_1.content2" />
            </TextWrapper>
            <TextWrapper>
              <I18n t="description_1.content3" />
            </TextWrapper>
          </TextSection>

        </Content>

        <Duo pic1={pic1} pic2={pic2} />

        <div className={styles["yaam__btn-section"]} />
        <Footer lang={props.match.params.locale} />
      </Wrapper>
    </ThemeProvider>
  );
}