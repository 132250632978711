import './App.css';
import LandingPage from './pages/Landing'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

const base = "/:locale(en|fr|ja)?";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path={base} render={props => <LandingPage {...props} />} />
        </Switch>
      </Router>

    </div>
  );
}

export default App;
