 const theme = {
  colors: {
      blazeOrange: '#ff5d00',
      orange: '#ff701e',
      flushOrange: '#ff7300',
      karry: '#ffe8da',
      white: '#ffffff',
      black: '#000000',
      yellow: '#ffe600',
      grey: '#cdcdcd',
      darkRed: '#a20000',
      lightRed: '#fdcece',
      darkGreen: '#107310',
      lightGreen: '#bfffbf',
      tundora: '#484848',
      text: '#4a4a4a'
  }
};

export default theme