import React from "react";
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 30%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 2% 1%;
  color: ${props => props.theme.colors.blazeOrange};
  text-align: center;

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 5% 0;
    margin-top: 20px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`

const Title = styled.h3`
  @media screen and (max-width: 767px) {
    width: 85%;
  }

  margin: 0 auto 20px;    
  width: 95%;
  font-size: 1.9rem;
  }
`

const ImageWrapper = styled.div`
  @media screen and (max-width: 767px) {
    width: 85%;
  }

  overflow: hidden;
  width: 95%;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
`
const Image = styled.div`
  @media screen and (max-width: 767px) {
    height: 300px;
  }

  @media screen and (min-width: 768px) {
    ${Wrapper}:hover & {
      transform: scale(1.2);
    }
  }
  white-space: pre-line;
  position: relative;
  transition: all 0.3s ease;
  height: 370px;
  background-size: cover;
  background-position: center;

  :before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    transition: all 0.3s ease;

    @media screen and (min-width: 768px) {
      ${Wrapper}:hover & {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
`

const Text = styled.p`
  transition: all 0.2s linear;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: white;
  opacity: 0;
  z-index: 20;
  width: 80%;
  font-size: 1.6rem;

  @media screen and (min-width: 768px) {
    ${Wrapper}:hover & {
      opacity: 1;
    }
  }
`

export default function Tile(props) {
  return (
    <Wrapper>
      <Title>{props.title}</Title>
      <ImageWrapper>
        <Image
          style={{ backgroundImage: `url(${props.bg})` }}
        >
          <Text>{props.txt}</Text>
        </Image>
      </ImageWrapper>
    </Wrapper>
  );
}
