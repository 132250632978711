import React from "react";
import styled from 'styled-components'
import Arrow from "../Arrow"

const Wrapper = styled.div`
  position: relative;
  height: unset;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: black;

  :before {
    content: "";
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media screen and (max-width: 767px) {
    height: 580px;
  }
`
const Text = styled.div`
  @media screen and (max-width: 767px) {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding-left: 13px;
  text-align: center;
  z-index: 101;
  `

const Title = styled.h1`
  @media screen and (max-width: 767px) {
    font-size: 9.5vw;
    width: unset;
    text-align: center;
  }
  
  width: 1000px;
  margin: 0 auto;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  font-size: 8rem;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  `

const Content = styled.span`
  @media screen and (max-width: 767px) {
    font-size: 7.5vw;
    text-align: center;
  }

  display: block;
  text-decoration: none;
  margin-top: 20px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
`

const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%; 

  //EDGE polyfill
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  //

  @media screen and (max-width: 767px) {
    width: 100vw;
    height: 580px;
    
    ${props => props.size === 'web' ? ' display: none;' : null}
  }
  
  @media screen and (min-width: 767px) {
    ${props => props.size === 'mobile' ? 'display: none;' : null}
  }
`

const ArrowContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101;
`


export default function HeroVideo(props) {
  return (
    <Wrapper>
      <Text>
        <Title>{props.ttl}</Title>
        <Content>
          {props.children}
        </Content>
      </Text>
      <Video preload="true" loop autoPlay muted size="web">
        <source src={props.video} type="video/mp4" />
      </Video>
      <Video preload="true" loop autoPlay muted size='mobile'>
        <source src={props.mobileVideo} type="video/mp4" />
      </Video>
      <ArrowContainer>
        <Arrow color="blazeOrange" />
      </ArrowContainer>
    </Wrapper>
  );
}
