import React from "react";
import styled from 'styled-components'

const Wrapper = styled.div`
  @media screen and (max-width: 767px) {
    height: unset;
    padding: 50px 0;
  }

  text-align: center;
  height: 640px;
  background: linear-gradient(
    to top,
    ${props => props.theme.colors['white']} 0%,
    ${props => props.theme.colors['white']}  70%,
    ${props => props.theme.colors['blazeOrange']}  70%,
    ${props => props.theme.colors['blazeOrange']}  100%
  );

  ${props => props.color === 'orange' ?
    `height: auto; 
    padding: 40px 0 80px;
    background: ${props.theme.colors['karry']};`
    : null}

  ${props => props.color === 'white' ?
    `
    height: auto;
    padding: 40px 0px 80px;
    background: ${props.theme.colors['white']};`
    : null}
`

const Title = styled.span`
  @media screen and (max-width: 767px) {
    width: calc(100% - 20px);
    line-height: 2;
    font-size: 9vw;
  }

  margin: 0 auto;
  font-size: 35px;
  font-weight: bold;
  color: var(--white);
  padding-bottom: 10px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.5);
  border-bottom: 3px solid ${props => props.theme.colors['white']};

  ${props => props.color === 'orange' || props.color === 'white' ?
    ` text-shadow: none;
      box-shadow: none;
      border-bottom: 3px solid var(--bright-orange);
      color: ${props.theme.colors['blazeOrange']};
      border-bottom: 3px solid ${props.theme.colors['blazeOrange']};`
    :
    `
    color: ${props.theme.colors['white']}
    border-bottom: 3px solid ${props.theme.colors['white']};`
  }
`
const Content = styled.div`
  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 90%;
  }

  margin: 50px auto 0;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  max-width: 1100px;
  padding: 0 10px;

  ${props => props.flex === 'row' ? 
  `
  width: 90%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  ` : null}
}
`

export default function Display(props) {
  return (
    <Wrapper color={props.color}>
      <Title color={props.color}>
        {props.title}
      </Title>
      <Content flex={props.flex}>
        {props.children}
      </Content>
    </Wrapper>
  );
}
