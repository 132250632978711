import { createI18n } from "react-router-i18n";

const locales = ["en", "fr", "ja"];

const translations = {
  // ----------------------- ENGLISH -----------------------

  en: {
    hero: {
      title: "YAAM CAFE & BAR",
      content: `Integrated Cobot + AI Solutions`
    },
    description_1: {
      title: "YAAM CAFE & BAR",
      content1: `Yaam Café & Bar is an end-to end (from order to delivery) cobot + AI solution to serve fresh, 
      quality drinks to customers, including payment system integration and mobile ordering app.`,
      content2: `We are using AI-driven personalized product recommendations based on computer vision features, 
      as well as object classification for system monitoring and product quality control.`,
      content3: `On the back-end we are utilizing deep learning for predictive analytics based on our own product
       feature selection and feature engineering. The analytics dashboard provides café & bar operators with valuable customer insights.`
    },
    display: {
      title: "KEY FEATURES",
      tile_1: `CONSISTENT`,
      hover_1: `Cobot Operates Reliably ~24/7`,
      tile_2: `DURABLE`,
      hover_2: `35,000 Hours Cobot Minimum Rated Life`,
      tile_3: `AI-DRIVEN`,
      hover_3: `Personalized Drink Recommendations`
    },
    description_2: {
      title: "EMBEDDED AI",
      content: `Yaam Café & Bar entertains customers by incorporating cutting-edge technologies, 
      such as AI computer vision-based personalized drink recommendations. Please contact us if
      you would like to find out more details or request a Yaam Café & Bar product brochure!`
    },
    btn: "PDF Brochure"
  },

  // ----------------------- FRENCH -----------------------

  fr: {},

  // ----------------------- JAPANESE -----------------------

  ja: {
    hero: {
      title: `YAAM カフェ & バー`,
      content: `コボットとAIソリューションの統合`
    },
    description_1: {
      title: "YAAM カフェ & バー",
      content1: `Yaamカフェ・バーは、注文対応からデータ分析まで全てを行うコボットとAIソリューションです。
      来店客に新鮮で高品質な飲料商品を提供します。`,
      content2: `コンピュータビジョンの特性を活かし、AIを使って来店客1人1人にあわせた飲料商品を提案し、
      システムモニタリングや品質管理にはオブジェクト分類を活用します。`,
      content3: `バックエンドではディープラーニングを用いて、商品の特徴選択や特徴量エンジニアリングに
      基づいた予測分析を行います。ダッシュボードからは、有益な顧客インサイトを得ることができます。`
    },
    display: {
      title: `主な特徴`,
      tile_1: `一貫性`,
      hover_1: `コボットが365日24時間稼働`,
      tile_2: `耐久性`,
      hover_2: `コボットの定格寿命は
      最低35,000時間`,
      tile_3: `AI主導`,
      hover_3: `来店客1人1人に
      あわせた飲料商品を提案`
    },
    btn: "PDFパンフレット"
  }
};

const I81n = createI18n(locales, translations);

export default I81n;
