import React from "react";
import styled from 'styled-components'

const Wrapper = styled.div`
  @media screen and (max-width: 767px) {
    padding: 35px 0px;
  }

  padding: 100px 0;
  background: linear-gradient(
    to right,
    ${props => props.theme.colors['blazeOrange']} 0%,
    ${props => props.theme.colors['yellow']} 100%
  );
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 0 10px;
    flex-direction: column;
  }

  padding: 0 20px;
  max-width: 1140px;
  margin: 0 auto;
`

const Photo = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  width: 45%;
  max-width: 500px;
  border-radius: 10px;

  @media screen and (max-width: 767px) {
    width: 100%;

    :nth-of-type(2){
      margin-top: 20px;
    }
  }

  ::before{ 
    content: '';
    display: block;
    padding-bottom: 70.5%;
    
    @media screen and (max-width: 767px) {
      padding-bottom: 85%;
    }
  }
`

export default function DuoImage(props) {
  return (
    <Wrapper>
      <Content>
        <Photo image={props.pic1} />
        <Photo image={props.pic2} />
      </Content>
    </Wrapper>
  );
}
