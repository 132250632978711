import { createI18n } from "react-router-i18n";

const locales = ["en", "fr", "ja"];

const translations = {
  // ----------------------- ENGLISH -----------------------

  en: {
    touch: `STAY IN TOUCH`,
    address: {
      title: `ADDRESS`,
      street: `Shiroyama Trust Tower 4th Fl., Toranomon 4-3-1, Minato-ku, Tokyo, 105-6004 Japan`
    },
    company: `BrightCore, Inc. `,
    terms: `Terms & Conditions`,
    privacy: `Privacy Policy`,
  },

  // ----------------------- FRENCH -----------------------

  fr: {},

  // ----------------------- JAPANESE -----------------------

  ja: {
    touch: `ご連絡はこちら`,
    address: {
      title: `住所`,
      street: `〒105-6004 東京都港区虎ノ門4-3-1 城山トラストタワー4階`
    },
    company: `BrightCore 株式会社 `,
    terms: `利用規約`,
    privacy: `プライバシー・ポリシー`,
  }
};

const I81n = createI18n(locales, translations);

export default I81n;
