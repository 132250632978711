import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Button from '../Button'
import styled from 'styled-components'

const ButtonWrapper = styled.div`
  @media screen and (max-width: 767px) {
    margin: 50px auto 0;
    text-align: center;
  }

  margin-top: 80px;
  text-align: left;

  a {
    text-decoration: none;
  }
`

const Wrapper = styled.div`
  @media screen and (max-width: 767px) {
    flex-direction: column;
    text-align: center;
    padding: 50px 0 0;

    :first-of-type {
      padding: 0px;
    }
  }

  margin: 0 auto;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;

  padding: 70px 0 0;
  
  :first-of-type {
    padding: 0px;
  }
`
const Image = styled.div`
  @media screen and (max-width: 767px) {
    width: auto;
    margin-top: 60px;
  }

  margin-top: 0;
  width: 415px;
  height: 505px;
  background-size: cover;
  background-position: left;
  ${props => props.hide ? 'display: none;' : null}
  ${props => props.right ? 'background-position: right;' : null}
}
`

const Content = styled.div`
  @media screen and (max-width: 767px) {
    padding: 0;
    text-align: center;
    ${props => props.layout === 'reverse' ? 'padding: 0;text-align: center;' : null}
  }
  
  max-width: 600px;
  width: 100%;
  padding: 0 20px 0 0;
  ${props => props.hide ? 'max-width: unset;' : null}
  ${props => props.layout === 'reverse' ? 'text-align: right;' : null}
`

const Text = styled.div`
  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 6vw;
    padding: 0 20px;
    margin-bottom: 0;
  }

  white-space: pre-line;
  margin-top: 60px;
  text-align: left;
  font-size: 2.4rem;
  line-height: 2;
  letter-spacing: normal;
  color: ${props => props.theme.colors['text']};
  line-height: 1.5;

  ${props => props.lh === 'n' ? 'line-height: unset;' : null}
}
`

const Title = styled.span`
  @media screen and (max-width: 767px) {
    font-size: 9vw;
    line-height: 2;
  }

  line-height: normal;
  padding-bottom: 10px;
  font-size: 3.5rem;
  font-weight: bold;
  color: ${props => props.theme.colors.blazeOrange};
  border-bottom: 2px solid ${props => props.theme.colors.blazeOrange};
`

const TitleWrapper = styled.div`
@media screen and (max-width: 767px) {
  text-align: center;
  ${props => props.layout === 'reverse' ? 'margin-top: 30px;' : null}
}

text-align: left;

${props => props.layout === 'reverse' ? 'text-align: right;' : null}
`


export default function Description(props) {
  return (
    <Wrapper>
      <Content hide={props.hidden} layout={props.layout}>
        <TitleWrapper layout={props.layout}>
          <Title>{props.title}</Title>
        </TitleWrapper>
        <Text>
          {props.children}
        </Text>
        {props.btn ? (
          <ButtonWrapper>
            <Link to={props.to}>
              <Button bold>{props.btn}</Button>
            </Link>
          </ButtonWrapper>
        ) : null}
      </Content>
      <Fade distance={"40px"} right>
        <Image style={{ backgroundImage: `url(${props.pic})` }} hide={props.hidden} />
      </Fade>
    </Wrapper>
  );
}
