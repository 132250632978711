import React from 'react'
import styled from 'styled-components'

const ArrowWrapper = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
    animation: mymove 2s infinite;
    
    @keyframes mymove {
        0% {top: 0px;}
        50% {top: 10px;}
        100% {top: 0px;}
    }
    
    :before { 
        content: "";
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50%;
        border-top: 5px solid ${props => props.theme.colors[props.color]};
        border-right: 5px solid ${props => props.theme.colors[props.color]};
        transform: translateX(-50%) rotate(135deg);  
        border-radius: 5px;
    }
`

export default function Arrow(props) {
    return (
        <ArrowWrapper color={props.color}/>
    )
}